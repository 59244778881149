@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-gradient-orange {
    background: linear-gradient(282deg, #FF6654 0%, #FDA463 100%);
}

.btn-orange {
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    background: #FF6654;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: block;
}

.btn-orange:hover {
    background-color: #ff6654d9;
}

.card-gradient-red {
    border-radius: 10px;
    background: linear-gradient(33deg, #FF6654 17.35%, #DC1B05 105.08%);
}

.form-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #e2e8f0;
    border-width: 1px;
    border-radius: .25rem;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
}